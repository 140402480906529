import { Clock, Mail, Smartphone } from 'lucide-react'
import { BasicModal } from '@/components'
import InputUI from '@/shared/inputs/InputUI'

const Help = ({ modal, setModal }) => {
  return (
    <BasicModal
      title='Help'
      open={modal}
      onOpenChange={setModal}
      classNameModal='h-screen mt-20 md:mt-auto md:h-auto md:translate-y-[-50%] max-w-none md:max-w-[665px]'
    >
      <div className='h-full flex-column gap-5 overflow-y-auto'>
        <div className='px-5 py-4 bg-light-200 rounded-lg border border-light-300'>
          <h2 className='mb-10 text-gray-300 font-semibold text-[18px]'>Administrative Contact Information</h2>
          <div>
            <div className='flex-column md:flex-space-between'>
              <div className='mb-2'>
                <h4 className='font-semibold text-gray-300'>Robert Fox</h4>
                <p className='ring-gray-200 font-medium text-[14px]'>Super Admin</p>
              </div>
              <div className='flex md:justify-end'>
                <InputUI
                  readOnly
                  placeholder={'Mon-Fri, 9 AM - 5 PM'}
                  icon={<Clock size={16} color={'#5C6BFA'} />}
                  className='pl-10 pr-0 py-0 h-[30px] cursor-text text-[12px] w-full md:w-auto'
                />
              </div>
            </div>
            <div className='mt-3 grid grid-cols-1 md:grid-cols-2 gap-2'>
              <InputUI
                readOnly
                copyIcon
                value={'(+33)7 00 55 55 11)'}
                icon={<Smartphone size={16} color={'#5C6BFA'} />}
              />
              <InputUI
                readOnly
                copyIcon
                value={'jennings@example.com'}
                icon={<Mail size={16} color={'#5C6BFA'} />}
              />
            </div>
          </div>
        </div>
        <div className='px-5 py-4 bg-light-200 rounded-lg border border-light-300'>
          <h2 className='mb-10 text-gray-300 font-semibold text-[18px]'>Quality Contact Information:</h2>
          <div className='flex-column md:flex-space-between'>
            <div className='mb-2'>
              <h4 className='font-semibold text-gray-300'>Sarada Minor</h4>
              <p className='ring-gray-200 font-medium text-[14px]'>HR</p>
            </div>
            <div className='flex md:justify-end'>
              <InputUI
                readOnly
                placeholder={'Mon-Fri, 9 AM - 5 PM'}
                icon={<Clock size={16} color={'#5C6BFA'} />}
                className='pl-10 pr-0 py-0 h-[30px] cursor-text text-[12px] w-full md:w-auto'
              />
            </div>
          </div>
          <div className='mt-3 grid grid-cols-1 md:grid-cols-2 gap-2'>
            <InputUI
              readOnly
              copyIcon
              value={'(+33)7 00 55 55 11)'}
              icon={<Smartphone size={16} color={'#5C6BFA'} />}
            />
            <InputUI
              readOnly
              copyIcon
              value={'vue@example.com'}
              icon={<Mail size={16} color={'#5C6BFA'} />}
            />
          </div>
        </div>
        <div className='px-5 py-4 bg-light-200 rounded-lg border border-light-300'>
          <h2 className='mb-10 text-gray-300 font-semibold text-[18px]'>Emergency Contact Information:</h2>
          <div className='flex-column md:flex-space-between'>
            <div className='mb-2'>
              <h4 className='font-semibold text-gray-300'>Sergio Lun</h4>
              <p className='ring-gray-200 font-medium text-[14px]'>Super Admin</p>
            </div>
            <div className='flex md:justify-end'>
              <InputUI
                readOnly
                placeholder={'24/7 emergency line'}
                icon={<Clock size={16} color={'#5C6BFA'} />}
                className='pl-10 pr-0 py-0 h-[30px] cursor-text text-[12px]'
              />
            </div>
          </div>
          <div className='mt-3 grid grid-cols-1 md:grid-cols-2 gap-2'>
            <InputUI
              readOnly
              copyIcon
              value={'(+33)7 00 55 55 12)'}
              icon={<Smartphone size={16} color={'#5C6BFA'} />}
            />
            <InputUI
              readOnly
              copyIcon
              value={'avi@example.com'}
              icon={<Mail size={16} color={'#5C6BFA'} />}
            />
          </div>
        </div>
      </div>
    </BasicModal>
  )
}

export default Help

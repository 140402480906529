export const QUERY_KEYS = {
  userMe: 'user-me',

  // Members
  members: 'members',
  member: 'member',
  memberJobs: 'member-jobs',
  memberStatuses: 'member-statuses',
  memberRoles: 'member-roles',
  memberNames: 'member-names',

  // Jobs
  jobs: 'jobs',
  job: 'job',
  jobStatuses: 'job-statuses',
  jobProofs: 'job-proofs',
  jobTasks: 'job-tasks',

  // Libraries
  libraryAuth: 'library-auth',
  libraryAircraft: 'library-aircraft',

  // Calibrated tools
  libraryCalibratedTools: 'calibrated-tools',
  libraryCalibratedTool: 'calibrated-tool',

  // Drug and Alcohol Tests
  tests: 'tests',

  // OJT
  ojtHours: 'ojt-hours',

  // Trainings
  trainingRequests: 'training-requests',
  trainingTypes: 'training-types',

  // PDF Templates
  pdfTemplates: 'pdf-templates',
  pdfRequests: 'pdf-requests',
  pdfRequest: 'pdf-request',

  // Schedule
  schedule: 'schedule',
  scheduleVacations: 'schedule-vacations',
  scheduleStatistics: 'schedule-statistics',

  // Customers
  customer: 'customer',
  customerJobs: 'customer-jobs',
  customers: 'customers',
  customersNames: 'customers-names',

  // Categories
  categories: 'categories',

  // Consumables
  consumables: 'consumables',

  // Chats
  chats: 'chats',
  chat: 'chat',
  chatUnreadMessages: 'unread-messages',

  // Notifications
  notifications: 'notifications',
  unreadNotifications: 'unread-notifications'
}

import { QueryClient, QueryClientProvider } from 'react-query'
// import { ReactQueryDevtools } from 'react-query/devtools'

const queryClient = new QueryClient({
    defaultOptions: {
        queries: { refetchOnWindowFocus: false }
    }
})

const ReactQueryProvider = ({ children }: { children: React.ReactNode }) => (
    <QueryClientProvider client={queryClient}>
        {children}
        {/*<ReactQueryDevtools />*/}
    </QueryClientProvider>
)

export default ReactQueryProvider

const { VITE_BASE_URL, VITE_MERCURE_URL } = import.meta.env

class ChatService {
  private static instance: ChatService
  private eventSources: Map<number, EventSource> = new Map()

  private constructor() {}

  public static getInstance(): ChatService {
    if (!ChatService.instance) {
      ChatService.instance = new ChatService()
    }
    return ChatService.instance
  }

  public subscribe(chatId: number, callback: (data: any) => void): void {
    if (this.eventSources.has(chatId)) return // Вже підписані на цей чат

    const url = `${VITE_BASE_URL}/${VITE_MERCURE_URL}?topic=chat/${String(chatId)}`
    const eventSource = new EventSource(url)

    eventSource.addEventListener('message', (event) => {
      const data = JSON.parse(event.data)
      console.log('Parsed message data:', data)
      callback(data)
    })

    eventSource.onopen = () => {
      console.log('Підключення до EventSource встановлено')
    }

    eventSource.onerror = () => {
      console.error(`ChatService: Error with EventSource for chat ${chatId}`)
      this.unsubscribe(chatId)
    }

    this.eventSources.set(chatId, eventSource)
  }

  public unsubscribe(chatId: number): void {
    const eventSource = this.eventSources.get(chatId)
    if (eventSource) {
      eventSource.close()
      this.eventSources.delete(chatId)
    }
  }
}

export default ChatService

import React, { forwardRef } from 'react'
import { Button as ShadcnButton } from '@/shared/ui/button'
import { twc } from 'react-twc'

const BaseButton = twc(ShadcnButton)`
  flex items-center justify-center gap-2
  font-medium rounded transition-colors duration-300
  focus:outline-none focus:ring-offset-2
`

const variants = {
  default: twc(BaseButton)`
    bg-primary text-white
    focus:ring-primary
  `,
  secondary: twc(BaseButton)`
    bg-gray-200 text-gray-800 hover:bg-gray-300
    focus:ring-gray-400
  `,
  outline: twc(BaseButton)`
    bg-transparent border border-primary text-primary
    hover:bg-primary hover:text-white
    focus:ring-primary
  `,
  outlineSecondary: twc(BaseButton)`
    bg-transparent border border-gray-100 text-gray-100
    hover:bg-gray-100 hover:text-white
    focus:ring-primary
  `,
  transparent: twc(BaseButton)`
    bg-transparent text-primary
    hover:bg-primary hover:text-white
    focus:ring-primary
  `
}

export type ButtonProps = React.ComponentProps<typeof ShadcnButton> & {
  variant?: keyof typeof variants
}

export const ButtonUI = forwardRef<HTMLButtonElement, ButtonProps>(
  ({ variant = 'default', children, ...props }, ref) => {
    const VariantButton = variants[variant]

    return <VariantButton ref={ref} {...props}>{children}</VariantButton>
  }
)

ButtonUI.displayName = 'ButtonUI'

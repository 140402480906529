import { useRef, useState } from 'react'
import { useMutation } from 'react-query'
import { toast } from 'sonner'
import { CircleAlert, FilePlus, Trash2 } from 'lucide-react'
import { BasicModal } from '@/components'
import { IFile } from '@/types/files'
import { Textarea } from '@/shared/ui/textarea'
import { fetchCreateReport } from '@/lib/report'
import { fetchDeleteFileById, fetchUploadFile } from '@/lib/files'
import PdfViewer from '@/pages/pdf-templates/components/PdfViewer'

const Report = ({ modal, setModal }) => {
  const [message, setMessage] = useState<string>('')
  const [previewFiles, setPreviewFiles] = useState<IFile[]>([])
  const fileInputRef = useRef<HTMLInputElement | null>(null)

  const sendMessage = useMutation(
    () => fetchCreateReport({
      description: message,
      ...(previewFiles.length && { attachments: previewFiles.map(file => ({ id: file.id })) })
    }),
    {
      onSuccess: () => {
        setModal(false)
        toast.success('Message sent')
      },
      onError: () => {
        toast.error('Not successful')
      }
    }
  )

  const handleKeyDown = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault()

      if (message.trim() || previewFiles.length > 0) {
        sendMessage.mutate()
      }
    }
  }

  const removePreviewFile = async (fileToRemove: IFile) => {
    try {
      await fetchDeleteFileById(String(fileToRemove.id))
      setPreviewFiles(prevFiles => prevFiles.filter(file => file.id !== fileToRemove.id))
    } catch (error) {
      toast.error('Failed to delete file')
    }
  }

  const handleFileChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files

    if (files) {
      try {
        const uploadPromises = Array.from(files).map(file => fetchUploadFile(file))
        const uploadedFiles = await Promise.all(uploadPromises)

        setPreviewFiles(prev => [...prev, ...uploadedFiles.filter(Boolean)])
      } catch (error) {
        toast.error('Failed to upload files')
      }
    }

    event.target.value = ''
  }

  return (
    <section className='h-full bg-light-200 overflow-y-auto'>
      <BasicModal
        open={modal}
        title='Hazard Report'
        onOpenChange={setModal}
        classNameModal='max-w-[500px]'
      >
        <div className='flex-column gap-5 justify-between'>
          <div className='p-2 flex-center-y gap-4 border border-primary rounded-lg'>
            <CircleAlert color={'#5C6BFA'} strokeWidth={1.5} size={28} />
            <span className='text-gray-300 font-medium text-[14px]'>
              Please provide all necessary details of the incident in a single message.
            </span>
          </div>

          <div className='relative flex-column bg-white'>
            <div className='pl-4 h-[45px] flex-space-between bg-primary rounded-t-lg overflow-hidden'>
              <div className='flex-center-y gap-4'>
                <div className='relative'>
                  <FilePlus
                    onClick={() => fileInputRef.current?.click()}
                    color='white'
                    strokeWidth={1}
                    className='cursor-pointer hover:stroke-green-100'
                  />
                  <input
                    type='file'
                    ref={fileInputRef}
                    multiple
                    style={{ display: 'none' }}
                    // accept='image/png,image/jpeg,image/jpg,image/svg+xml,image/webp,application/pdf'
                    onChange={handleFileChange}
                  />
                </div>
              </div>
              <button
                disabled={!message.trim() && previewFiles.length === 0}
                onClick={() => sendMessage.mutate()}
                className='h-full min-w-24 text-white font-semibold hover:bg-green-100 duration-300 disabled:hover:bg-transparent'
              >
                Send
              </button>
            </div>

            {previewFiles.length > 0 && (
              <div className='flex flex-wrap gap-2 p-2 bg-[#F9F9FB] border-x border-t border-light-100'>
                {previewFiles.map((file, index) => (
                  <div key={file.id || index} className='relative'>
                    {file.url?.includes('.pdf') ? (
                      <PdfViewer
                        width='100%'
                        height={150}
                        url={file.url}
                      />
                    ) : (
                      file?.url?.match(/\.(mp4|webm|mov)$/i) ?
                        <video
                          controls
                          src={file.url}
                          className='w-full h-20 object-cover rounded'
                        />
                        :
                        <img
                          alt='Image'
                          src={file.url}
                          className='w-20 h-20 object-cover rounded'
                        />
                    )}
                    <button
                      onClick={() => removePreviewFile(file)}
                      className='absolute -top-2 -right-2 bg-red-500 text-white rounded-full p-1'
                    >
                      <Trash2 size={12} />
                    </button>
                  </div>
                ))}
              </div>
            )}

            <Textarea
              rows={6}
              value={message}
              onKeyDown={handleKeyDown}
              placeholder='Type a message...'
              onChange={(e) => setMessage(e.target.value)}
              className='w-full p-2 bg-[#F9F9FB] border border-light-100 rounded-none rounded-b-lg overflow-y-auto focus-visible:border-primary'
            />
          </div>
        </div>
      </BasicModal>
    </section>
  )
}

export default Report

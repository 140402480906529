import { useState, useEffect } from 'react'

interface PdfViewerProps {
  url: string;
  width: string | number;
  height: string | number;
}

const PdfViewer: React.FC<PdfViewerProps> = ({ url, width, height }) => {
  const [pdfBlobUrl, setPdfBlobUrl] = useState<string | null>(null)

  useEffect(() => {
    const fetchPdf = async () => {
      try {
        const response = await fetch(url)
        const blob = await response.blob()
        const blobUrl = URL.createObjectURL(blob)
        setPdfBlobUrl(blobUrl)
      } catch (error) {
        console.error('Error fetching PDF:', error)
      }
    }

    fetchPdf()

    return () => {
      if (pdfBlobUrl) {
        URL.revokeObjectURL(pdfBlobUrl)
      }
    }
  }, [url])

  if (!pdfBlobUrl) {
    return <div>Loading PDF...</div>
  }

  return (
    <iframe
      src={pdfBlobUrl}
      width={width}
      height={height}
      style={{ border: 'none' }}
      title='PDF Viewer'
    />
  )
}

export default PdfViewer

export const roleLabels = {
  'ROLE_SA': 'Super Admin',
  'ROLE_HR': 'HR',
  'ROLE_QA': 'Quality Manager',
  'ROLE_DOM': 'Director of Maintenance',
  'ROLE_CC': 'Crew Chief',
  'ROLE_WORKER': 'Worker',
  'ROLE_CUSTOMER': 'Customer',
  'ROLE_FAA': 'FAA',
  'ROLE_USER': 'User',
}

export const getRoleLabel = (role) => {
  return roleLabels[role] || 'Unknown Role'
}

export const roles = ['Super Admin', 'HR', 'Quality Manager', 'Director of Maintenance', 'Crew Chief', 'Worker']

export const getRoleKeyByLabel = (label) => {
  const roleLabels = {
    'Super Admin': 'ROLE_SA',
    'HR': 'ROLE_HR',
    'Quality Manager': 'ROLE_QA',
    'Director of Maintenance': 'ROLE_DOM',
    'Crew Chief': 'ROLE_CC',
    'Worker': 'ROLE_WORKER',
    'Customer': 'ROLE_CUSTOMER',
  }

  return roleLabels[label] || null
}

export const GLOBAL_ROLES = {
  'ROLE_SA': 'ROLE_SA',
  'ROLE_HR': 'ROLE_HR',
  'ROLE_QA': 'ROLE_QA',
  'ROLE_DOM': 'ROLE_DOM',
  'ROLE_CC': 'ROLE_CC',
  'ROLE_WORKER': 'ROLE_WORKER',
  'ROLE_CUSTOMER': 'ROLE_CUSTOMER',
  'ROLE_FAA': 'ROLE_FAA',
  'ROLE_USER': 'ROLE_USER'
}

import * as React from 'react'
import { cn } from '@/utils/utils'

export interface InputProps
  extends React.InputHTMLAttributes<HTMLInputElement> {
  label?: string
  error?: string | boolean,
  optionalFiled?: boolean
}

const Input = React.forwardRef<HTMLInputElement, InputProps>(
  ({ className, error, type, optionalFiled, label, name, ...props }, ref) => {
    return (
      <label className='relative w-full space-y-1'>
        {label ? (
          <div className='flex-space-between text-gray-300 font-normal'>{label} {optionalFiled && <span className='text-[#B6B7CD] font-medium text-[12px]'>(Optional)</span>}</div>
        ) : null}
        <input
          type={type}
          className={cn(
                    `${error ? 'border-red-100' : 'border-input'}`,
            'mt-0 flex h-10 w-full rounded-md border bg-background px-3 py-5 text-sm ring-offset-background file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-gray-50 focus-visible:outline-none focus-visible:ring-transparent disabled:cursor-not-allowed disabled:opacity-50',
            className
          )}
          ref={ref}
          {...props}
        />
        {error ? <div className='absolute -bottom-[17px] text-red-100 text-xs'>{error}</div> : null}
      </label>
    )
  }
)
Input.displayName = 'Input'

export { Input }

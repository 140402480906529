import { Suspense } from 'react'
import { createBrowserRouter, Navigate } from 'react-router-dom'
import { PAGES_SLUGS } from '@/constants/pages'
import { ErrorBoundary } from '@/components'

import App from '../App'
import { GLOBAL_ROLES } from '@/utils/roles'
import ProtectedRoute from './ProtectedRoute'
import lazyWithRetry from '@/routes/lazyWithRetry'


// Lazy loading components
const Dashboard = lazyWithRetry(() => import('@/pages/dashboard/Dashboard'), 'Dashboard')
const TrainingRequests = lazyWithRetry(() => import('@/pages/training-requests/TrainingRequests'), 'TrainingRequests')
const TrainingTypes = lazyWithRetry(() => import('@/pages/training-types/TrainingTypes'), 'TrainingTypes')
const DrugAlcohol = lazyWithRetry(() => import('@/pages/drug-alcohol/DrugAlcohol'), 'DrugAlcohol')
const Schedule = lazyWithRetry(() => import('@/pages/schedule/Schedule'), 'Schedule')
const ScheduleSingle = lazyWithRetry(() => import('@/pages/schedule-single/ScheduleSingle'), 'ScheduleSingle')
const ScheduleVacationRequests = lazyWithRetry(() => import('@/pages/schedule-vacation/ScheduleVacation'), 'ScheduleVacation')
const ScheduleStatistics = lazyWithRetry(() => import('@/pages/schedule-statistics/ScheduleStatistics'), 'ScheduleStatistics')
const Members = lazyWithRetry(() => import('@/pages/members/Members'), 'Members')
const Member = lazyWithRetry(() => import('@/pages/member/Member'), 'Member')
const NewMember = lazyWithRetry(() => import('@/pages/new-member/NewMember'), 'NewMember')
const Jobs = lazyWithRetry(() => import('@/pages/jobs/Jobs'), 'Jobs')
const Job = lazyWithRetry(() => import('@/pages/job/Job'), 'Job')
const NewJob = lazyWithRetry(() => import('@/pages/new-job/NewJob'), 'NewJob')
const Login = lazyWithRetry(() => import('@/pages/auth/login/Login'), 'Login')
const ResetPassword = lazyWithRetry(() => import('@/pages/auth/reset-password/ResetPassword'), 'ResetPassword')
const CreatePassword = lazyWithRetry(() => import('@/pages/auth/create-password/CreatePassword'), 'CreatePassword')
const Capability = lazyWithRetry(() => import('@/pages/capability/Capability'), 'Capability')
const Consumables = lazyWithRetry(() => import('@/pages/consumables/Consumables'), 'Consumables')
const CalibratedTools = lazyWithRetry(() => import('@/pages/сalibrated-tools/CalibratedTools'), 'CalibratedTools')
const CustomersFaa = lazyWithRetry(() => import('@/pages/customers-faa/CustomersFaa'), 'CustomersFaa')
const Customer = lazyWithRetry(() => import('@/pages/customer/Customer'), 'Customer')
const NewCustomer = lazyWithRetry(() => import('@/pages/new-customer/NewCustomer'), 'NewCustomer')
const PdfTemplates = lazyWithRetry(() => import('@/pages/pdf-templates/PdfTemplates'), 'PdfTemplates')
const PdfRequests = lazyWithRetry(() => import('@/pages/pdf-requests/PdfRequests'), 'PdfRequests')
const Settings = lazyWithRetry(() => import('@/pages/settings/Settings'), 'Settings')
const Chat = lazyWithRetry(() => import('@/pages/chat/Chat'), 'Chat')
const Notifications = lazyWithRetry(() => import('@/pages/notifications/Notifications'), 'Notifications')
const AccessDenied = lazyWithRetry(() => import('@/pages/error/AccessDenied'), 'AccessDenied')
const NotFound = lazyWithRetry(() => import('@/pages/error/NotFound'), 'NotFound')

const Loading = () => <div>Loading...</div>

const SuspenseWrapper = ({ children }) => <Suspense fallback={<Loading />}>{children}</Suspense>


export const router = createBrowserRouter([
  {
    path: PAGES_SLUGS.home,
    element: <Navigate to={PAGES_SLUGS.jobs} replace />,
  },
  {
    path: PAGES_SLUGS.home,
    element: <App />,
    errorElement: <ErrorBoundary />,
    children: [
      {
        element: <ProtectedRoute allowedRoles={[GLOBAL_ROLES.ROLE_SA, GLOBAL_ROLES.ROLE_HR, GLOBAL_ROLES.ROLE_QA, GLOBAL_ROLES.ROLE_DOM, GLOBAL_ROLES.ROLE_WORKER, GLOBAL_ROLES.ROLE_CC]} />,
        children: [{ path: PAGES_SLUGS.dashboardId, element: <SuspenseWrapper><Dashboard /></SuspenseWrapper> }],
        errorElement: <ErrorBoundary />
      },
      {
        element: <ProtectedRoute allowedRoles={[GLOBAL_ROLES.ROLE_SA, GLOBAL_ROLES.ROLE_HR, GLOBAL_ROLES.ROLE_QA, GLOBAL_ROLES.ROLE_DOM, GLOBAL_ROLES.ROLE_WORKER, GLOBAL_ROLES.ROLE_CC]} />,
        children: [{ path: PAGES_SLUGS.editDashboardId, element: <SuspenseWrapper><NewMember /></SuspenseWrapper> }],
        errorElement: <ErrorBoundary />
      },

      // Members
      {
        element: <ProtectedRoute allowedRoles={[GLOBAL_ROLES.ROLE_SA, GLOBAL_ROLES.ROLE_HR, GLOBAL_ROLES.ROLE_QA, GLOBAL_ROLES.ROLE_DOM]} />,
        children: [{ path: PAGES_SLUGS.members, element: <SuspenseWrapper><Members /></SuspenseWrapper> }],
        errorElement: <ErrorBoundary />
      },
      {
        element: <ProtectedRoute allowedRoles={[GLOBAL_ROLES.ROLE_SA, GLOBAL_ROLES.ROLE_HR, GLOBAL_ROLES.ROLE_QA, GLOBAL_ROLES.ROLE_DOM]} />,
        children: [{ path: PAGES_SLUGS.memberId, element: <SuspenseWrapper><Member /></SuspenseWrapper> }],
        errorElement: <ErrorBoundary />
      },
      {
        element: <ProtectedRoute allowedRoles={[GLOBAL_ROLES.ROLE_SA, GLOBAL_ROLES.ROLE_HR]} />,
        children: [{ path: PAGES_SLUGS.addNewMember, element: <SuspenseWrapper><NewMember /></SuspenseWrapper> }],
        errorElement: <ErrorBoundary />
      },
      {
        element: <ProtectedRoute allowedRoles={[GLOBAL_ROLES.ROLE_SA, GLOBAL_ROLES.ROLE_HR]} />,
        children: [{ path: PAGES_SLUGS.editMemberId, element: <SuspenseWrapper><NewMember /></SuspenseWrapper> }],
        errorElement: <ErrorBoundary />
      },

      // Jobs
      {
          element: <ProtectedRoute allowedRoles={[GLOBAL_ROLES.ROLE_SA, GLOBAL_ROLES.ROLE_HR, GLOBAL_ROLES.ROLE_QA, GLOBAL_ROLES.ROLE_DOM, GLOBAL_ROLES.ROLE_CC, GLOBAL_ROLES.ROLE_FAA, GLOBAL_ROLES.ROLE_CUSTOMER, GLOBAL_ROLES.ROLE_WORKER]} />,
        children: [{ path: PAGES_SLUGS.jobs, element: <SuspenseWrapper><Jobs /></SuspenseWrapper> }],
        errorElement: <ErrorBoundary />
      },
      {
        element: <ProtectedRoute allowedRoles={[GLOBAL_ROLES.ROLE_SA, GLOBAL_ROLES.ROLE_HR, GLOBAL_ROLES.ROLE_QA, GLOBAL_ROLES.ROLE_DOM, GLOBAL_ROLES.ROLE_CC, GLOBAL_ROLES.ROLE_FAA, GLOBAL_ROLES.ROLE_CUSTOMER, GLOBAL_ROLES.ROLE_WORKER]} />,
        children: [{ path: PAGES_SLUGS.jobId, element: <SuspenseWrapper><Job /></SuspenseWrapper> }],
        errorElement: <ErrorBoundary />
      },
      {
        element: <ProtectedRoute allowedRoles={[GLOBAL_ROLES.ROLE_SA, GLOBAL_ROLES.ROLE_DOM, GLOBAL_ROLES.ROLE_FAA]} />,
        children: [{ path: PAGES_SLUGS.addNewJob, element: <SuspenseWrapper><NewJob /></SuspenseWrapper> }],
        errorElement: <ErrorBoundary />
      },
      {
        element: <ProtectedRoute allowedRoles={[GLOBAL_ROLES.ROLE_SA, GLOBAL_ROLES.ROLE_DOM]} />,
        children: [{ path: PAGES_SLUGS.editJobId, element: <SuspenseWrapper><NewJob /></SuspenseWrapper> }],
        errorElement: <ErrorBoundary />
      },

      // Calibrated-Tools
      {
        element: <ProtectedRoute allowedRoles={[GLOBAL_ROLES.ROLE_SA, GLOBAL_ROLES.ROLE_DOM, GLOBAL_ROLES.ROLE_QA, GLOBAL_ROLES.ROLE_CC]} />,
        children: [{ path: PAGES_SLUGS.calibratedTools, element: <SuspenseWrapper><CalibratedTools /></SuspenseWrapper> }],
        errorElement: <ErrorBoundary />
      },

      // Consumables
      {
        element: <ProtectedRoute allowedRoles={[GLOBAL_ROLES.ROLE_SA, GLOBAL_ROLES.ROLE_DOM, GLOBAL_ROLES.ROLE_QA, GLOBAL_ROLES.ROLE_CC]} />,
        children: [{ path: PAGES_SLUGS.consumables, element: <SuspenseWrapper><Consumables /></SuspenseWrapper> } ],
        errorElement: <ErrorBoundary />
      },

      // Capability ---> Aircrafts && Customers
      {
        element: <ProtectedRoute allowedRoles={[GLOBAL_ROLES.ROLE_SA, GLOBAL_ROLES.ROLE_DOM, GLOBAL_ROLES.ROLE_QA, GLOBAL_ROLES.ROLE_CC]} />,
        children: [{ path: PAGES_SLUGS.capability, element: <SuspenseWrapper><Capability /></SuspenseWrapper> } ],
        errorElement: <ErrorBoundary />
      },

      // Customers
      {
        element: <ProtectedRoute allowedRoles={[GLOBAL_ROLES.ROLE_SA, GLOBAL_ROLES.ROLE_DOM, GLOBAL_ROLES.ROLE_QA, GLOBAL_ROLES.ROLE_HR]} />,
        children: [{ path: PAGES_SLUGS.customers, element: <SuspenseWrapper><CustomersFaa /></SuspenseWrapper> } ],
        errorElement: <ErrorBoundary />
      },
      {
        element: <ProtectedRoute allowedRoles={[GLOBAL_ROLES.ROLE_SA, GLOBAL_ROLES.ROLE_DOM, GLOBAL_ROLES.ROLE_QA, GLOBAL_ROLES.ROLE_HR]} />,
        children: [{ path: PAGES_SLUGS.customerId, element: <SuspenseWrapper><Customer /></SuspenseWrapper> } ],
        errorElement: <ErrorBoundary />
      },
      {
        element: <ProtectedRoute allowedRoles={[GLOBAL_ROLES.ROLE_SA, GLOBAL_ROLES.ROLE_DOM, GLOBAL_ROLES.ROLE_HR]} />,
        children: [{ path: PAGES_SLUGS.addNewCustomer, element: <SuspenseWrapper><NewCustomer /></SuspenseWrapper> } ],
        errorElement: <ErrorBoundary />
      },
      {
        element: <ProtectedRoute allowedRoles={[GLOBAL_ROLES.ROLE_SA, GLOBAL_ROLES.ROLE_DOM, GLOBAL_ROLES.ROLE_HR]} />,
        children: [{ path: PAGES_SLUGS.editCustomerId, element: <SuspenseWrapper><NewCustomer /></SuspenseWrapper> } ],
        errorElement: <ErrorBoundary />
      },

      // Trainings
      {
        element: <ProtectedRoute allowedRoles={[GLOBAL_ROLES.ROLE_SA, GLOBAL_ROLES.ROLE_QA, GLOBAL_ROLES.ROLE_HR]} />,
        children: [{ path: PAGES_SLUGS.trainingTypes, element: <SuspenseWrapper><TrainingTypes /></SuspenseWrapper> }],
        errorElement: <ErrorBoundary />
      },
      {
        element: <ProtectedRoute allowedRoles={[GLOBAL_ROLES.ROLE_SA, GLOBAL_ROLES.ROLE_QA, GLOBAL_ROLES.ROLE_DOM, GLOBAL_ROLES.ROLE_HR]} />,
        children: [{ path: PAGES_SLUGS.trainingRequests, element: <SuspenseWrapper><TrainingRequests /></SuspenseWrapper> }],
        errorElement: <ErrorBoundary />
      },

      // DrugAlcohol
      {
        element: <ProtectedRoute allowedRoles={[GLOBAL_ROLES.ROLE_SA, GLOBAL_ROLES.ROLE_QA, GLOBAL_ROLES.ROLE_HR]} />,
        children: [{ path: PAGES_SLUGS.drugAlcohol, element: <SuspenseWrapper><DrugAlcohol /></SuspenseWrapper> }],
        errorElement: <ErrorBoundary />
      },

      // Schedule
      {
        element: <ProtectedRoute allowedRoles={[GLOBAL_ROLES.ROLE_SA, GLOBAL_ROLES.ROLE_QA, GLOBAL_ROLES.ROLE_HR, GLOBAL_ROLES.ROLE_DOM, GLOBAL_ROLES.ROLE_CC]} />,
        children: [{ path: PAGES_SLUGS.schedule, element: <SuspenseWrapper><Schedule /></SuspenseWrapper> }],
        errorElement: <ErrorBoundary />
      },
      {
        element: <ProtectedRoute allowedRoles={[GLOBAL_ROLES.ROLE_SA, GLOBAL_ROLES.ROLE_QA, GLOBAL_ROLES.ROLE_HR, GLOBAL_ROLES.ROLE_DOM]} />,
        children: [{ path: PAGES_SLUGS.scheduleVacationRequests, element: <SuspenseWrapper><ScheduleVacationRequests /></SuspenseWrapper> }],
        errorElement: <ErrorBoundary />
      },
      {
        element: <ProtectedRoute allowedRoles={[GLOBAL_ROLES.ROLE_SA, GLOBAL_ROLES.ROLE_QA, GLOBAL_ROLES.ROLE_HR, GLOBAL_ROLES.ROLE_DOM]} />,
        children: [{ path: PAGES_SLUGS.scheduleStatistics, element: <SuspenseWrapper><ScheduleStatistics /></SuspenseWrapper> }],
        errorElement: <ErrorBoundary />
      },
      {
        element: <ProtectedRoute allowedRoles={[GLOBAL_ROLES.ROLE_SA, GLOBAL_ROLES.ROLE_QA, GLOBAL_ROLES.ROLE_HR, GLOBAL_ROLES.ROLE_DOM, GLOBAL_ROLES.ROLE_CC]} />,
        children: [{ path: PAGES_SLUGS.scheduleId, element: <SuspenseWrapper><ScheduleSingle /></SuspenseWrapper> }],
        errorElement: <ErrorBoundary />
      },

      // PDF Templates
      {
        element: <ProtectedRoute allowedRoles={[GLOBAL_ROLES.ROLE_SA, GLOBAL_ROLES.ROLE_QA, GLOBAL_ROLES.ROLE_HR, GLOBAL_ROLES.ROLE_DOM, GLOBAL_ROLES.ROLE_CC, GLOBAL_ROLES.ROLE_USER, GLOBAL_ROLES.ROLE_CUSTOMER, GLOBAL_ROLES.ROLE_WORKER]} />,
        children: [{ path: PAGES_SLUGS.pdfTemplates, element: <SuspenseWrapper><PdfTemplates /> </SuspenseWrapper>}],
        errorElement: <ErrorBoundary />
      },
      {
        element: <ProtectedRoute allowedRoles={[GLOBAL_ROLES.ROLE_SA, GLOBAL_ROLES.ROLE_QA, GLOBAL_ROLES.ROLE_HR, GLOBAL_ROLES.ROLE_DOM]} />,
        children: [{ path: PAGES_SLUGS.pdfRequests, element: <SuspenseWrapper><PdfRequests /></SuspenseWrapper> }],
        errorElement: <ErrorBoundary />
      },

      // Auth
      {
        path: PAGES_SLUGS.login,
        element: <SuspenseWrapper><Login /></SuspenseWrapper>,
        errorElement: <ErrorBoundary />,
      },
      {
        path: PAGES_SLUGS.acceptInvite,
        element: <SuspenseWrapper><CreatePassword /></SuspenseWrapper>,
        errorElement: <ErrorBoundary />,
      },
      {
        path: PAGES_SLUGS.createPassword,
        element: <SuspenseWrapper><CreatePassword /></SuspenseWrapper>,
        errorElement: <ErrorBoundary />,
      },
      {
        path: PAGES_SLUGS.resetPassword,
        element: <SuspenseWrapper><ResetPassword /></SuspenseWrapper>,
        errorElement: <ErrorBoundary />,
      },
      {
        path: PAGES_SLUGS.verifyPassword,
        element: <SuspenseWrapper><CreatePassword /></SuspenseWrapper>,
        errorElement: <ErrorBoundary />,
      },

      // Account settings
      {
        element: <ProtectedRoute allowedRoles={[GLOBAL_ROLES.ROLE_SA, GLOBAL_ROLES.ROLE_QA, GLOBAL_ROLES.ROLE_HR, GLOBAL_ROLES.ROLE_DOM, GLOBAL_ROLES.ROLE_CC,  GLOBAL_ROLES.ROLE_WORKER,  GLOBAL_ROLES.ROLE_CUSTOMER, GLOBAL_ROLES.ROLE_FAA]} />,
        children: [{ path: PAGES_SLUGS.settings, element: <SuspenseWrapper><Settings /> </SuspenseWrapper>}],
        errorElement: <ErrorBoundary />
      },

      // Chat
      {
        element: <ProtectedRoute allowedRoles={[GLOBAL_ROLES.ROLE_SA, GLOBAL_ROLES.ROLE_QA, GLOBAL_ROLES.ROLE_HR, GLOBAL_ROLES.ROLE_DOM, GLOBAL_ROLES.ROLE_CC, GLOBAL_ROLES.ROLE_WORKER]} />,
        children: [{ path: PAGES_SLUGS.chat, element: <SuspenseWrapper><Chat /> </SuspenseWrapper>}],
        errorElement: <ErrorBoundary />
      },

      // Notifications
      {
        element: <ProtectedRoute allowedRoles={[GLOBAL_ROLES.ROLE_SA, GLOBAL_ROLES.ROLE_QA, GLOBAL_ROLES.ROLE_HR, GLOBAL_ROLES.ROLE_DOM, GLOBAL_ROLES.ROLE_CC, GLOBAL_ROLES.ROLE_WORKER, GLOBAL_ROLES.ROLE_CUSTOMER, GLOBAL_ROLES.ROLE_FAA]} />,
        children: [{ path: PAGES_SLUGS.notifications, element: <SuspenseWrapper><Notifications /> </SuspenseWrapper>}],
        errorElement: <ErrorBoundary />
      },

      // Access Denied
      {
        path: PAGES_SLUGS.accessDenied,
        element: <SuspenseWrapper><AccessDenied /></SuspenseWrapper>,
      },

      // Not-Found
      {
        path: PAGES_SLUGS.notFound,
        element: <SuspenseWrapper><NotFound /></SuspenseWrapper>,
      },
    ]
  }
])

import React, { createContext, useContext, useEffect, useState } from 'react'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { useAuth } from '@/context/AuthContext'
import { QUERY_KEYS } from '@/constants/query-keys'
import NotificationService from '@/lib/services/NotificationService'
import { INotification } from '@/types/notifications'
import { fetchGetAllNotReadNotifications, fetchNotificationRead } from '@/lib/notifacations'

interface NotificationContextProps {
  markAllAsRead: () => void
  unreadMessages: Record<number, any>
  notifications: INotification[]
  resetNotification: (chatId?: number) => void
}

const NotificationContext = createContext<NotificationContextProps | undefined>(undefined)

export const NotificationProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const queryClient = useQueryClient()
  const [unreadMessages, setUnreadMessages] = useState<Record<number, any>>({})
  const [notifications, setNotifications] = useState<INotification[]>([])
  const { userId } = useAuth()

  useQuery(
    QUERY_KEYS.unreadNotifications,
    fetchGetAllNotReadNotifications,
    {
      enabled: !!userId,
      initialData: [],
      onSuccess: (data) => {setNotifications(data) }
    }
  )

  useEffect(() => {
    if (userId) {
      const notificationService = NotificationService.getInstance()

      // Підписка на повідомлення
      notificationService.subscribe(String(userId), (message) => {
        const chatId = message.chat

        if (message.sender.id !== userId && message.status === 'sent') {
          setUnreadMessages(prev => ({
            ...prev,
            [chatId]: {
              ...message,
              count: (prev[chatId]?.count || 0) + 1
            }
          }))
        }

        queryClient.invalidateQueries(QUERY_KEYS.chats)
        queryClient.invalidateQueries([QUERY_KEYS.chat, chatId])
      })

      // Підписка на нотифікації
      notificationService.subscribeToNotifications(String(userId), (notification: INotification) => {
        setNotifications(prev => [notification, ...prev])
      })
    }

    return () => {
      const notificationService = NotificationService.getInstance()

      notificationService.closeConnection()
      notificationService.closeNotificationsConnection()
    }
  }, [userId])

  const resetNotification = (chatId?: number) => {
    if (chatId) {
      setUnreadMessages(prev => {
        const { [chatId]: _, ...rest } = prev
        return rest
      })
      queryClient.invalidateQueries(QUERY_KEYS.chats)
      queryClient.invalidateQueries([QUERY_KEYS.chat, chatId])
    } else {
      setUnreadMessages({})
      queryClient.invalidateQueries(QUERY_KEYS.chats)
    }
  }

  const markAllAsReadMutation = useMutation(
    () => Promise.all(notifications.map(notification => fetchNotificationRead(String(notification.id)))),
    {
      onSuccess: () => {
        // Оновлюємо нотифікації, щоб усі позначилися як прочитані
        setNotifications(prev => prev.map(notification => ({ ...notification, status: 'read' })))
        queryClient.invalidateQueries(QUERY_KEYS.unreadNotifications)
      }
    }
  )

  const markAllAsRead = () => markAllAsReadMutation.mutate()

  return (
    <NotificationContext.Provider value={{ resetNotification, unreadMessages, notifications, markAllAsRead }}>
      {children}
    </NotificationContext.Provider>
  )
}

export const useNotification = () => {
  const context = useContext(NotificationContext)

  if (!context) {
    throw new Error('useNotification must be used within a NotificationProvider')
  }

  return context
}

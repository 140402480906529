import axiosInstance from '@/lib/instance'
import Endpoints from '@/lib/endpoints'

interface IReport {
  description: string
  attachments?: { id: number }[]
}

export const fetchCreateReport = async (options: IReport): Promise<IReport> => {
  try {
    const response = await axiosInstance.post(Endpoints.HAZARD_REPORT.HAZARD_REPORT, options)

    return response.data
  } catch (error) {
    console.error('Error fetching create report:', error)

    throw error
  }
}

import React, { FC, InputHTMLAttributes, ReactNode, useState } from 'react'
import { Copy } from 'lucide-react'
import { TooltipUI } from '@/components'
import { Input } from '@/shared/ui/input'

interface InputUIProps extends InputHTMLAttributes<HTMLInputElement> {
  value?: string
  placeholder?: string
  icon?: ReactNode
  copyIcon?: ReactNode
  readOnly?: boolean
  iconPosition?: 'left' | 'right'
}

const InputUI: FC<InputUIProps> = ({ value, placeholder, icon, copyIcon, readOnly, iconPosition = 'left', ...props }) => {
  const [copied, setCopied] = useState(false)

  const handleMouseDown = (event: React.MouseEvent<HTMLInputElement>) => {
    if (readOnly) {
      event.preventDefault()
    }
  }

  const handleCopy = () => {
    navigator.clipboard.writeText(value || '')
    setCopied(true)
    setTimeout(() => setCopied(false), 2000)
  }

  return (
    <div className='relative'>
      { icon &&
        <span
          className={`absolute top-1/2 transform -translate-y-1/2 z-10 cursor-pointer ${iconPosition === 'left' ? 'left-3' : 'right-3'}`}
        >
          {icon}
        </span>
      }
      <Input
        value={value}
        placeholder={placeholder}
        readOnly={readOnly}
        onMouseDown={handleMouseDown}
        className='w-full pl-10 pr-3 py-2 text-sm'
        {...props}
      />

      {copyIcon &&
        <TooltipUI
          trigger={
            <Copy
              size={16}
              color={'#5C6BFA'}
              onClick={handleCopy}
              className='bg-white absolute right-2 top-1/2 -translate-y-1/2 cursor-pointer'
            />
          }
          content={'Copy'}
        />
      }

      {
        copied &&
          <div className='px-2 py-0 flex-center-xy absolute -top-4 right-0 bg-primary text-white text-[14px] rounded-md z-10'>
            Copied
          </div>
      }
    </div>
  )
}

export default InputUI

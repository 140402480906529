import axiosInstance from '@/lib/instance'
import Endpoints from '@/lib/endpoints'
import { INotificationResponse } from '@/types/notifications'

interface QueryParams {
  sort?: string
  page?: number
  order?: string
  userId?: string
  search?: string
  endDate?: string
  startDate?: string
  itemsPerPage?: number
}

export const fetchGetAllNotifications = async (params: QueryParams): Promise<INotificationResponse> => {
  try {
    const response = await axiosInstance.get(Endpoints.NOTIFICATIONS.NOTIFICATIONS, {
      params: {
        ...params,
        sort: params.sort,
        order: params.order,
      }
    })

    return response.data
  } catch (error) {
    console.error('Error fetching notifications:', error)

    throw error
  }
}

export const fetchNotificationRead = async (id: string) => {
  try {
    const response = await axiosInstance.put(`${Endpoints.NOTIFICATIONS.NOTIFICATIONS}/${id}/${Endpoints.NOTIFICATIONS.READ}`)

    return response.data
  } catch (error) {
    console.error('Error fetching notification read:', error)

    throw error
  }
}

export const fetchGetAllNotReadNotifications= async () => {
  try {
    const response = await axiosInstance.get(`${Endpoints.NOTIFICATIONS.NOTIFICATIONS}/${Endpoints.NOTIFICATIONS.UNREAD}`)

    return response.data
  } catch (error) {
    console.error('Error fetching list not read notifications:', error)

    throw error
  }
}

import { useState, useRef } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { CircleHelp, Mail, Menu, MessageCircle, Settings, TriangleAlert, X } from 'lucide-react'
import { TooltipUI } from '@/components'
import { PAGES_SLUGS } from '@/constants/pages'
import { useUser } from '@/context/UserContext'
import { ButtonUI } from '@/shared/buttons/ButtonUI'
import NotificationsPopup from './NotificationsPopup'
import { useNotification } from '@/context/NotificationContext'
import Help from './Help'
import Report from './Report'

interface NavigationProps {
  mobileMenu: boolean
  toggleMenu: () => void
  isLogin: boolean
}

const Navigation = ({ mobileMenu, toggleMenu, isLogin }: NavigationProps) => {
  const navigate = useNavigate()
  const location = useLocation()
  const [showNotifications, setShowNotifications] = useState<boolean>(false)
  const timerRef = useRef<NodeJS.Timeout | null>(null)
  const [reportModal, setReportModal] = useState<boolean>(false)
  const [helpModal, setHelpModal] = useState<boolean>(false)

  const chatPage = location.pathname.includes(PAGES_SLUGS.chat)
  const settingsPage = location.pathname.includes(PAGES_SLUGS.settings)
  const notificationsPage = location.pathname.includes(PAGES_SLUGS.notifications)

  const { SA_QA_HR_DOM_CC, isWorker } = useUser()
  const { unreadMessages, notifications, markAllAsRead } = useNotification()

  const unreadNotificationsCount = notifications?.filter(notification => notification.status === 'sent')?.length
  const hasUnreadMessages = Object.keys(unreadMessages).length > 0


  const handleMouseEnter = () => {
    if (timerRef.current) {
      clearTimeout(timerRef.current)
      timerRef.current = null
    }
    setShowNotifications(true)
  }

  const handleMouseLeave = () => {
    timerRef.current = setTimeout(() => {
      setShowNotifications(false)
    }, 300)  // Затримка 300 мс
  }

  const handleNotificationsClick = (e) => {
    e.preventDefault()

    markAllAsRead()
    navigate(PAGES_SLUGS.notifications)
  }

  return (
    <>
      <nav className='w-full flex-space-between'>
        <div className='flex-center-y font-medium'></div>
        {
          !isLogin &&
          <div className='flex-center-y gap-6 md:gap-10 relative'>
            <TooltipUI
              content='Hazad report'
              trigger={
                <ButtonUI
                  variant='outline'
                  onClick={() => setReportModal(true)}
                  className={`p-2 flex-center-y gap-2 font-medium border rounded-lg hover:border-primary duration-300
                    ${reportModal ? 'text-primary border-primary' : 'text-white border-white hover:text-white'}
                  `}
                >
                  <span className='hidden md:inline'>Hazard report</span>
                  <TriangleAlert strokeWidth={1.3} size={18} />
                </ButtonUI>
              }
            />
            <TooltipUI
              content='Need help'
              trigger={
                <div className='relative'>
                  <CircleHelp
                    size={18}
                    strokeWidth={1.3}
                    onClick={() => setHelpModal(true)}
                    className={`hover:stroke-primary cursor-pointer duration-300 ${helpModal && 'stroke-primary'}`}
                  />
                </div>
              }
            />
            {
              (SA_QA_HR_DOM_CC || isWorker) &&
                <TooltipUI
                  content='Chat'
                  trigger={
                    <div className='relative'>
                      <MessageCircle
                        size={18}
                        strokeWidth={1}
                        onClick={chatPage ? () => navigate(-1) : () => navigate(PAGES_SLUGS.chat)}
                        className={`hover:stroke-primary cursor-pointer duration-300 ${chatPage && 'stroke-primary'}`}
                      />
                      {
                        hasUnreadMessages &&
                          <div className='w-3 h-3 absolute -top-1 -right-1 bg-primary border-2 border-dark-default z-10 rounded-lg' />
                      }
                    </div>
                  }
                />
            }
            <div
              className='relative'
              onMouseEnter={handleMouseEnter}
              onMouseLeave={handleMouseLeave}
            >
              <TooltipUI
                content=''
                trigger={
                  <div className='relative'>
                    <Mail
                      size={18}
                      strokeWidth={1}
                      className={`hover:stroke-primary cursor-pointer duration-300 ${notificationsPage && 'stroke-primary'}`}
                      onClick={handleNotificationsClick}
                    />
                    {
                      unreadNotificationsCount > 0 &&
                        <div className='w-3 h-3 absolute -top-1 -right-1 bg-primary border-2 border-dark-default z-10 rounded-lg' />
                    }
                  </div>
                }
              />
              {
                showNotifications &&
                  <NotificationsPopup
                    notifications={notifications}
                    handleNotificationsClick={handleNotificationsClick}
                  />
              }
            </div>
            <TooltipUI
              className='absolute z-[100] top-0 right-5'
              content='Account settings'
              trigger={
                <Settings
                  size={18}
                  strokeWidth={1}
                  className={`hidden md:inline hover:stroke-primary cursor-pointer duration-300 ${settingsPage && 'stroke-primary'}`}
                  onClick={() => navigate(PAGES_SLUGS.settings)}
                />
              }
            />
            <span onClick={toggleMenu} className='block lg:hidden'>
              {mobileMenu ? <X /> : <Menu />}
            </span>
          </div>
        }
      </nav>

      { helpModal && <Help modal={helpModal} setModal={setHelpModal} /> }
      { reportModal && <Report modal={reportModal} setModal={setReportModal} /> }
    </>
  )
}

export default Navigation

const { VITE_BASE_URL, VITE_MERCURE_URL } = import.meta.env

class NotificationService {
  private static instance: NotificationService
  private eventSource: EventSource | null = null
  private notificationSource: EventSource | null = null

  private constructor() {}

  public static getInstance(): NotificationService {
    if (!NotificationService.instance) {
      NotificationService.instance = new NotificationService()
    }
    return NotificationService.instance
  }

  public subscribe(userId: string, callback: (data: any) => void): void {
    if (this.eventSource) return // Вже підписані

    const url = `${VITE_BASE_URL}/${VITE_MERCURE_URL}?topic=user/${String(userId)}`
    this.eventSource = new EventSource(url)

    this.eventSource.addEventListener('message', (event) => {
      const data = JSON.parse(event.data)
      callback(data)
    })

    this.eventSource.onopen = () => {
      console.info(`Активовано підписку глабальну на юзера: ${userId}`)
    }

    this.eventSource.onerror = () => {
      console.error(`Помилка глобальної підписки на юзера ${userId}`)
      this.closeConnection()
    }
  }

  public subscribeToNotifications(userId: string, callback: (data: any) => void): void {
    if (this.notificationSource) return // Вже підписані на нотифікації

    const url = `${VITE_BASE_URL}/${VITE_MERCURE_URL}?topic=user/${String(userId)}/notifications`
    this.notificationSource = new EventSource(url)

    this.notificationSource.addEventListener('message', (event) => {
      const data = JSON.parse(event.data)
      callback(data)
    })

    this.notificationSource.onopen = () => {
      console.info(`Підписка на нотифікації для користувача: ${userId}`)
    }

    this.notificationSource.onerror = () => {
      console.error(`Помилка підписки на нотифікації для користувача ${userId}`)
      this.closeNotificationsConnection()
    }
  }

  public closeConnection(): void {
    if (this.eventSource) {
      this.eventSource.close()
      this.eventSource = null
    }
  }

  public closeNotificationsConnection(): void {
    if (this.notificationSource) {
      this.notificationSource.close()
      this.notificationSource = null
    }
  }
}

export default NotificationService

import { Outlet } from 'react-router-dom'
import { Toaster } from 'sonner'
import { ChatProvider } from '@/context/ChatContext'
import { AuthProvider } from '@/context/AuthContext'
import { UserProvider } from '@/context/UserContext'
import RootLayout from '@/components/layouts/RootLayout'
import { GeneralProvider } from '@/context/GeneralContext'
import { PageWrapper } from '@/components/layouts/PageWrapper'
import { LoaderWrapper } from '@/components/layouts/LoaderWrapper'
import { NotificationProvider } from '@/context/NotificationContext'

const App = () => (
  <GeneralProvider>
    <AuthProvider>
      <UserProvider>
        <NotificationProvider>
          <ChatProvider>
            <PageWrapper>
              <RootLayout>
                <LoaderWrapper>
                  <Outlet />
                </LoaderWrapper>
              </RootLayout>
              <Toaster richColors />
            </PageWrapper>
          </ChatProvider>
        </NotificationProvider>
      </UserProvider>
    </AuthProvider>
  </GeneralProvider>
)

export default App

import { useId, ReactNode } from 'react'
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from '@/shared/ui/tooltip'

interface TooltipUIProps {
  trigger: ReactNode
  content?: ReactNode | string
  tooltipKey?: any
  className?: string
}

export const TooltipUI = ({ trigger, content, tooltipKey, className }: TooltipUIProps) => {
  const id = useId()

  return (
    <TooltipProvider>
      <Tooltip key={tooltipKey || id.toString()}>
        <TooltipTrigger asChild>
          {trigger}
        </TooltipTrigger>
        <TooltipContent className={`${className} ${!content && 'opacity-0'}`}>
          {content}
        </TooltipContent>
      </Tooltip>
    </TooltipProvider>
  )
}

import * as React from 'react'
import { cn } from '@/utils/utils'

export interface TextareaProps
  extends React.TextareaHTMLAttributes<HTMLTextAreaElement> {
  label?: string
  error?: string | boolean
  optionalFiled?: boolean
  classNameLabel?: string
}

const Textarea = React.forwardRef<HTMLTextAreaElement, TextareaProps>(
  ({ className, label, classNameLabel, optionalFiled, error, ...props }, ref) => {
    return (
      <label className='space-y-1'>
        {label ? (
          <div className='flex-space-between text-gray-300 font-medium'>
            <span className={`${classNameLabel}`}>{label}{' '}</span>
            {optionalFiled && (
              <span className='text-[#B6B7CD] font-medium text-[12px]'>
                (Optional)
              </span>
            )}
          </div>
        ) : null}
        <textarea
          className={cn(
            `${error ? 'border-red-100' : 'border-input'}`,
            'flex min-h-[80px] w-full rounded-md border bg-background px-3 py-2 text-sm ring-offset-background placeholder:text-muted-foreground focus-visible:outline-none focus-visible:border-gray-200 focus-visible:ring-ring focus-visible:ring-offset-1 disabled:cursor-not-allowed disabled:opacity-50',
            className
          )}
          ref={ref}
          {...props}
        />
        {error ? <div className='text-red-100 text-xs'>{error}</div> : null}
      </label>
    )
  }
)
Textarea.displayName = 'Textarea'

export { Textarea }

import {
  Dialog,
  DialogContent, DialogDescription,
  DialogHeader,
  DialogTitle
} from '@/shared/ui/dialog'

interface BasicModalProps {
  children: React.ReactNode
  title: string
  open: boolean
  onOpenChange: (open: boolean) => void
  classNameModal?: string
}

export const BasicModal = ({
  children,
  open,
  onOpenChange,
  title = 'Save changes',
  classNameModal
}: BasicModalProps) => {
  return (
    <Dialog open={open} onOpenChange={onOpenChange}>
      <DialogContent
        aria-describedby={`Description`}
        className={`max-w-[400px] px-0 pt-0 pb-7  rounded-t-lg ${classNameModal}`}
      >
        <DialogHeader className='mb-3 flex flex-row h-12  md:h-auto items-center justify-between p-3 text-white bg-primary rounded-t-lg'>
          <DialogTitle className='text-[18px]'>{title}</DialogTitle>
        </DialogHeader>
        <DialogDescription className='sr-only'>
          {title} dialog description
        </DialogDescription>
        <div className='flex-column gap-7 px-7 text-gray-200 font-medium'>
          {children}
        </div>
      </DialogContent>
    </Dialog>
  )
}

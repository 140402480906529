import { ReactNode, useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { useAuth } from '@/context/AuthContext'
import { useLocalStorage } from '@/hooks/useLocalStorage'
import { NON_AUTHORIZED_PAGES, PAGES_SLUGS } from '@/constants/pages'


export const PageWrapper = ({ children }: { children: ReactNode }) => {
  const navigate = useNavigate()
  const { isAuth } = useAuth()
  const { getStorageValue } = useLocalStorage()
  const savedAu = getStorageValue<boolean>('isAuth')
  const token = getStorageValue('token')
  const location = useLocation()

  useEffect(() => {
    const authValue = savedAu && token && isAuth

    if (!authValue) {
      if (!NON_AUTHORIZED_PAGES.includes(location.pathname)) {
        !location.pathname.includes(PAGES_SLUGS.login) &&
          navigate(PAGES_SLUGS.login)
      }
    }
  }, [location])

  return <>{children}</>
}

import axiosInstance from '@/lib/instance'
import Endpoints from '@/lib/endpoints'
import { IChat, IChats, ICrateMessageChat } from '@/types/chat'

export const fetchGetAllChats = async (): Promise<IChats[]> => {
  try {
    const response = await axiosInstance.get(Endpoints.CHATS.CHATS)

    return response.data
  } catch (error) {
    console.error('Error fetching chats:', error)

    throw error
  }
}

export const fetchCreateChat = async (id: string): Promise<IChats> => {
  try {
    const response = await axiosInstance.post(`${Endpoints.CHATS.CHATS_RECIPIENT}/${id}`)

    return response.data
  } catch (error) {
    console.error('Error fetching create chat:', error)

    throw error
  }
}

export const fetchGetAllChatRecipientById = async (id: string): Promise<IChat[]> => {
  try {
    const response = await axiosInstance.get(`${Endpoints.CHATS.CHATS_RECIPIENT}/${id}`)

    return response.data
  } catch (error) {
    console.error('Error fetching chat id:', error)

    throw error
  }
}

export const fetchCreateChatMessage = async (id: string, options: ICrateMessageChat): Promise<IChat> => {
  try {
    const response = await axiosInstance.post(`${Endpoints.CHATS.CHATS_RECIPIENT}/${id}/${Endpoints.CHATS.SEND}`, options)

    return response.data
  } catch (error) {
    console.error('Error fetching chat id:', error)

    throw error
  }
}

export const fetchChatMarkMessageAsRead = async (messageId: string): Promise<void> => {
  try {
    await axiosInstance.put(`${Endpoints.CHATS.CHATS_MESSAGE}/${messageId}/${Endpoints.CHATS.READ}`)
  } catch (error) {
    console.error('Error marking message as read:', error)
    throw error
  }
}

export const fetchChatMarkMessageAsDelivered = async (messageId: string): Promise<void> => {
  try {
    await axiosInstance.put(`${Endpoints.CHATS.CHATS_MESSAGE}/${messageId}/${Endpoints.CHATS.DELIVERED}`)
  } catch (error) {
    console.error('Error marking message as delivered:', error)
    throw error
  }
}

export const fetchChatUnreadMessages = async (): Promise<IChat[]> => {
  try {
    const response = await axiosInstance.get(`${Endpoints.CHATS.CHATS}/${Endpoints.CHATS.UNREAD}`)
    return response.data
  } catch (error) {
    console.error('Error fetching unread messages:', error)
    throw error
  }
}

import { MouseEvent } from 'react'
import { Link, useLocation } from 'react-router-dom'
import {
  Briefcase, Calendar, ChevronLeft, FileCode, FileText,
  Flag, Folder, Layers, LayoutGrid, LogOut,
  PanelsTopLeft, Plane, Server, Settings, User, Users
} from 'lucide-react'
import { GLOBAL_ROLES } from '@/utils/roles'
import { PAGES_SLUGS } from '@/constants/pages'
import { useAuth } from '@/context/AuthContext'
import { useUser } from '@/context/UserContext'
import { ButtonUI } from '@/shared/buttons/ButtonUI'
import './Sidebar.css'

const menuItems = [
  {
    name: 'Dashboard',
    path: PAGES_SLUGS.dashboard,
    roles: [GLOBAL_ROLES.ROLE_SA, GLOBAL_ROLES.ROLE_QA, GLOBAL_ROLES.ROLE_HR, GLOBAL_ROLES.ROLE_DOM, GLOBAL_ROLES.ROLE_CC, GLOBAL_ROLES.ROLE_WORKER],
    icon: <PanelsTopLeft strokeWidth={1} />
  },
  {
    name: 'Jobs',
    path: PAGES_SLUGS.jobs,
    roles: [GLOBAL_ROLES.ROLE_SA, GLOBAL_ROLES.ROLE_QA, GLOBAL_ROLES.ROLE_HR, GLOBAL_ROLES.ROLE_DOM, GLOBAL_ROLES.ROLE_CC, GLOBAL_ROLES.ROLE_CUSTOMER, GLOBAL_ROLES.ROLE_WORKER],
    icon: <Layers strokeWidth={1} />
  },
  {
    name: 'Members',
    path: PAGES_SLUGS.members,
    roles: [GLOBAL_ROLES.ROLE_SA, GLOBAL_ROLES.ROLE_QA, GLOBAL_ROLES.ROLE_HR, GLOBAL_ROLES.ROLE_DOM],
    icon: <Users strokeWidth={1} />
  },
  {
    name: 'Training Requests',
    path: PAGES_SLUGS.trainingRequests,
    roles: [GLOBAL_ROLES.ROLE_SA, GLOBAL_ROLES.ROLE_QA, GLOBAL_ROLES.ROLE_HR, GLOBAL_ROLES.ROLE_DOM],
    icon: <Briefcase strokeWidth={1} />
  },
  {
    name: 'Training Types',
    path: PAGES_SLUGS.trainingTypes,
    roles: [GLOBAL_ROLES.ROLE_SA, GLOBAL_ROLES.ROLE_QA, GLOBAL_ROLES.ROLE_HR],
    icon: <Folder strokeWidth={1} />
  },
  {
    name: 'D&A Program',
    path: PAGES_SLUGS.drugAlcohol,
    roles: [GLOBAL_ROLES.ROLE_SA, GLOBAL_ROLES.ROLE_QA, GLOBAL_ROLES.ROLE_HR],
    icon: <Flag strokeWidth={1} />
  },
  {
    name: 'Schedule',
    path: PAGES_SLUGS.schedule,
    roles: [GLOBAL_ROLES.ROLE_SA, GLOBAL_ROLES.ROLE_QA, GLOBAL_ROLES.ROLE_HR, GLOBAL_ROLES.ROLE_DOM, GLOBAL_ROLES.ROLE_CC],
    icon: <Calendar strokeWidth={1} />
  },
  {
    name: 'Consumables',
    path: PAGES_SLUGS.consumables,
    roles: [GLOBAL_ROLES.ROLE_SA, GLOBAL_ROLES.ROLE_QA, GLOBAL_ROLES.ROLE_DOM, GLOBAL_ROLES.ROLE_CC],
    icon: <LayoutGrid strokeWidth={1} />
  },
  {
    name: 'Calibrated Tools',
    path: PAGES_SLUGS.calibratedTools,
    roles: [GLOBAL_ROLES.ROLE_SA, GLOBAL_ROLES.ROLE_QA, GLOBAL_ROLES.ROLE_DOM, GLOBAL_ROLES.ROLE_CC],
    icon: <Server strokeWidth={1} />
  },
  {
    name: 'Capability List',
    path: PAGES_SLUGS.capability,
    roles: [GLOBAL_ROLES.ROLE_SA, GLOBAL_ROLES.ROLE_QA, GLOBAL_ROLES.ROLE_DOM, GLOBAL_ROLES.ROLE_CC],
    icon: <Plane strokeWidth={1} />
  },
  {
    name: 'Customers',
    path: PAGES_SLUGS.customers,
    roles: [GLOBAL_ROLES.ROLE_SA, GLOBAL_ROLES.ROLE_QA, GLOBAL_ROLES.ROLE_HR, GLOBAL_ROLES.ROLE_DOM],
    icon: <User strokeWidth={1} />
  },
  {
    name: 'PDF Library',
    path: PAGES_SLUGS.pdfTemplates,
    roles: [GLOBAL_ROLES.ROLE_SA, GLOBAL_ROLES.ROLE_QA, GLOBAL_ROLES.ROLE_HR, GLOBAL_ROLES.ROLE_DOM, GLOBAL_ROLES.ROLE_CC, GLOBAL_ROLES.ROLE_USER, GLOBAL_ROLES.ROLE_CUSTOMER, GLOBAL_ROLES.ROLE_WORKER],
    icon: <FileText strokeWidth={1} />
  },
  {
    name: 'PDF Requests',
    path: PAGES_SLUGS.pdfRequests,
    roles: [GLOBAL_ROLES.ROLE_SA, GLOBAL_ROLES.ROLE_QA, GLOBAL_ROLES.ROLE_HR, GLOBAL_ROLES.ROLE_DOM],
    icon: <FileCode strokeWidth={1} />
  }
]

interface SidebarProps {
  mobileMenu: boolean
  sidebarExpanded: boolean
  toggleSidebar: () => void
  setMobileMenu: (toggle: boolean) => void
}

const Sidebar = ({ mobileMenu, sidebarExpanded, toggleSidebar, setMobileMenu }: SidebarProps) => {
  const { onLogout, userId } = useAuth()
  const { role } = useUser()
  const location = useLocation()

  const isLinkActive = (path) => {
    return location.pathname.startsWith(path)
  }

  const handleLogout = (e: MouseEvent) => {
    e.preventDefault()
    onLogout()
  }

  return (
    <aside
      className={`sidebar ${!sidebarExpanded ? 'short' : ''} ${mobileMenu ? 'mobile' : ''}`}
      tabIndex={0}
      onBlur={() => setMobileMenu(false)}
    >
      <nav className='sidebar-nav h-full py-[40px] flex-column justify-between relative scrollbar-hide'>
        <div className='h-full flex-column'>
          {menuItems.map(item =>
            (!item.roles || (role && item.roles.includes(role))) &&
            <Link
              key={item.name}
              to={item.name === 'Dashboard' ? `${PAGES_SLUGS.dashboard}/${userId}` : item.path}
              onClick={() => setMobileMenu(false)}
              className={`sidebar-link
              ${(item.name === 'Consumables' || item.name === 'Customers') && 'mt-7'}
              ${isLinkActive(item.path) && 'active'}  `}
            >
              {sidebarExpanded && item.name}
              {!sidebarExpanded && item.icon}
              </Link>
          )}

          <Link
            key='settings'
            to={PAGES_SLUGS.settings}
            onClick={() => setMobileMenu(false)}
            className={`flex lg:hidden sidebar-link ${location.pathname.includes(PAGES_SLUGS.settings) && 'active'}  `}
          >

            { sidebarExpanded ? 'Settings' : <Settings strokeWidth={1} />  }
          </Link>
          <Link
            to=''
            key='logout'
            onClick={handleLogout}
            className='sidebar-link absolute bottom-0 left-0 right-0'
          >
            { sidebarExpanded ? 'Logout' : <LogOut strokeWidth={1} />  }
          </Link>
        </div>
      </nav>

      <ButtonUI
        className={`btn-toggle w-8 h-8 m-0 p-0 rounded-full bg-dark-default ${!sidebarExpanded && 'active'}`}
        onClick={toggleSidebar}
      >
        <ChevronLeft size={16} className={`duration-300 ${!sidebarExpanded && 'rotate-180'}`} />
      </ButtonUI>
    </aside>
  )
}

export default Sidebar

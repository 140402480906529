import React, { createContext, useContext, useState } from 'react'
import { useQueryClient } from 'react-query'
import ChatService from '@/lib/services/ChatService'
import { QUERY_KEYS } from '@/constants/query-keys'
import { fetchGetAllChatRecipientById, fetchChatMarkMessageAsRead } from '@/lib/chat'

interface ChatContextProps {
  messages: Record<number, any[]>
  subscribeToChat: (chatId: number) => void
  unsubscribeFromChat: (chatId: number) => void
  addMessage: (chatId: number, message: any) => void
  loadChatHistory: (chatId: number, memberId: number) => void
  updateMessageStatus: (chatId: number, messageId: string) => void
}

const ChatContext = createContext<ChatContextProps | undefined>(undefined)

export const ChatProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const queryClient = useQueryClient()
  const [messages, setMessages] = useState<Record<number, any[]>>({})

  const loadChatHistory = async (chatId: number, memberId: number) => {
    try {
      // Завантажуємо історію чату для конкретного користувача (memberId)
      const chatHistory = await fetchGetAllChatRecipientById(String(memberId))

      setMessages((prev) => ({
        ...prev,
        [chatId]: chatHistory
      }))

      if (chatHistory) {
        const sentMessages = chatHistory.filter(message => message.status === 'sent')

        for (const message of sentMessages) {
          await queryClient.invalidateQueries([QUERY_KEYS.chat, memberId])
          await queryClient.invalidateQueries([QUERY_KEYS.chat, message.sender.id])
        }
      }
    } catch (error) {
      console.error('Error loading chat history:', error)
    }
  }

  const updateMessageStatus = async (chatId: number, messageId: string) => {
    try {
      await fetchChatMarkMessageAsRead(messageId)

      // Оновлюємо локальний стан повідомлень
      setMessages(prev => ({
        ...prev,
        [chatId]: prev[chatId]?.map(message =>
          message.id === messageId
            ? { ...message, status: 'read' }
            : message
        ) || []
      }))

      // Інвалідуємо кеш для оновлення даних
      await queryClient.invalidateQueries(QUERY_KEYS.chats)
      await queryClient.invalidateQueries([QUERY_KEYS.chat, chatId])
    } catch (error) {
      console.error('Error updating message status:', error)
    }
  }

  const addMessage = (chatId: number, message: any) => {
    setMessages(prev => ({
      ...prev,
      [chatId]: [...(prev[chatId] || []), message]
    }))
  }

  const subscribeToChat = (chatId: number) => {
    const chatService = ChatService.getInstance()
    chatService.subscribe(chatId, (data) => addMessage(chatId, data))
  }

  const unsubscribeFromChat = (chatId: number) => {
    ChatService.getInstance().unsubscribe(chatId)
  }

  return (
    <ChatContext.Provider
      value={{
        messages,
        addMessage,
        loadChatHistory,
        subscribeToChat,
        unsubscribeFromChat,
        updateMessageStatus
      }}
    >
      {children}
    </ChatContext.Provider>
  )
}

export const useChat = () => {
  const context = useContext(ChatContext)

  if (!context) {
    throw new Error('useChat must be used within a ChatProvider')
  }

  return context
}

import axiosInstance from '@/lib/instance'
import Endpoints from '@/lib/endpoints'

interface IFile {
  id: number
  originalName: string
  category: string
  url: string
  size: number
}

export const fetchUploadFile = async (file) => {
  try {
    const formData = new FormData()
    formData.append('file', file)

    const response = await axiosInstance.post(`${Endpoints.FILES.FILES}`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      }
    })

    return response.data
  } catch (error) {
    console.error('Error fetching upload file:', error)

    return error
  }
}

export const fetchGetFileById = async (fileId: string): Promise<IFile> => {
  try {
    const response = await axiosInstance.get(`${Endpoints.FILES.FILES}/${fileId}`)

    return response.data
  } catch (error) {
    console.error('Error fetching get file:', error)

    throw error
  }
}

export const fetchDeleteFileById = async (fileId: string) => {
  try {
    const response = await axiosInstance.delete(`${Endpoints.FILES.FILES}/${fileId}`)

    return response.data
  } catch (error) {
    console.error('Error fetching delete file:', error)

    return error
  }
}

import { Mail } from 'lucide-react'
import { Link } from 'react-router-dom'
import { Tabs, TabsContent, TabsList, TabsTrigger } from '@/shared/ui/tabs'

const NotificationsPopup = ({ notifications, handleNotificationsClick }) => {


  return (
    <div className='absolute top-8 right-0 w-[350px] bg-white rounded-t-lg shadow-lg z-[999] overflow-hidden'>
      <div className='relative p-3 flex-center-y rounded-t-lg'>
        <h2 className='text-gray-300 font-semibold'>Notifications</h2>
        <Link
          to={''}
          onClick={handleNotificationsClick}
          className='px-4 absolute right-0 flex-center-xy text-primary cursor-pointer h-full hover:bg-primary hover:text-white'
        >
          Show all
        </Link>
      </div>
      <Tabs
        defaultValue='all'
        className='w-full lg:w-auto max-w-[100vw] bg-white'
      >
        <div className='overflow-x-auto bg-white'>
          <TabsList className='p-0 grid grid-cols-2 rounded-t-xl border-b h-[50px] bg-white'>
            <TabsTrigger
              value='all'
              className='p-0 h-full flex-center-y gap-1 border-b border-b-transparent rounded-b-none hover:border-b-primary data-[state=active]:border-b-primary'
            >
              <span className='text-gray-300 font-semibold'>All</span>
              <div className='px-2 bg-primary text-white font-medium text-[12px] rounded-lg'>
                {notifications?.length}
              </div>
            </TabsTrigger>
            <TabsTrigger
              value='unread'
              className='p-0 h-full flex-center-y gap-1 border-b border-b-transparent rounded-b-none hover:border-b-primary data-[state=active]:border-b-primary'
            >
              <span className='text-gray-300 font-semibold'>Unread</span>
              <div className='px-2 bg-gray-100 text-white font-medium text-[12px] rounded-lg'>
                {notifications.length}
              </div>
            </TabsTrigger>
          </TabsList>
        </div>
        <TabsContent value='all' className='m-0'>
          {
            notifications?.map(notification =>
              <div
                className='flex-space-between gap-4 mt-0 p-4 text-gray-300 bg-light-100 cursor-pointer'
                onClick={handleNotificationsClick}
              >
                <div className='w-20 h-10 flex-center-xy bg-white rounded-full'>
                  <svg width='41' height='17' viewBox='0 0 31 17' fill='none' xmlns='http://www.w3.org/2000/svg'>
                    <path d='M6.74731 16.8861L13.4092 1.93951H16.8256L23.5089 16.8861H19.879L14.4128 3.6904H15.7793L10.2918 16.8861H6.74731ZM10.0783 13.6833L10.9964 11.057H18.6833L19.6228 13.6833H10.0783Z' fill='#5C6BFA' />
                    <path d='M27.3781 7.01107L27.3904 11.6091L21.5935 10.4215L18.077 2.92066L26.3639 5.44392C26.9841 5.70434 27.3734 6.30722 27.3781 7.01107Z' fill='#5C6BFA' />
                    <path d='M27.3781 7.01107L27.3904 11.6091L21.5935 10.4215L18.077 2.92066L26.3639 5.44392C26.9841 5.70434 27.3734 6.30722 27.3781 7.01107Z' fill='#5C6BFA' />
                    <path d='M2.78591 7.01107L2.77365 11.6091L8.57058 10.4215L12.0871 2.92066L3.8002 5.44392C3.18001 5.70434 2.79069 6.30722 2.78591 7.01107Z' fill='#5C6BFA' />
                    <path d='M2.78591 7.01107L2.77365 11.6091L8.57058 10.4215L12.0871 2.92066L3.8002 5.44392C3.18001 5.70434 2.79069 6.30722 2.78591 7.01107Z' fill='#5C6BFA' />
                  </svg>
                </div>
                <div className='flex-column gap-1 truncate'>
                  <h3 className='font-semibold'>{notification.title}</h3>
                  <p className='truncate'>{notification.message}</p>
                </div>
                <Mail size={70} color='#5C6BFA' />
              </div>
            )
          }
        </TabsContent>
        <TabsContent value='unread' className='m-0'>
          {
            notifications?.map(notification =>
              <div
                className='flex-space-between gap-4 mt-0 p-4 text-gray-300 bg-light-100 cursor-pointer'
                onClick={handleNotificationsClick}
              >
                <div className='w-20 h-10 flex-center-xy bg-white rounded-full'>
                  <svg width='41' height='17' viewBox='0 0 31 17' fill='none' xmlns='http://www.w3.org/2000/svg'>
                    <path d='M6.74731 16.8861L13.4092 1.93951H16.8256L23.5089 16.8861H19.879L14.4128 3.6904H15.7793L10.2918 16.8861H6.74731ZM10.0783 13.6833L10.9964 11.057H18.6833L19.6228 13.6833H10.0783Z' fill='#5C6BFA' />
                    <path d='M27.3781 7.01107L27.3904 11.6091L21.5935 10.4215L18.077 2.92066L26.3639 5.44392C26.9841 5.70434 27.3734 6.30722 27.3781 7.01107Z' fill='#5C6BFA' />
                    <path d='M27.3781 7.01107L27.3904 11.6091L21.5935 10.4215L18.077 2.92066L26.3639 5.44392C26.9841 5.70434 27.3734 6.30722 27.3781 7.01107Z' fill='#5C6BFA' />
                    <path d='M2.78591 7.01107L2.77365 11.6091L8.57058 10.4215L12.0871 2.92066L3.8002 5.44392C3.18001 5.70434 2.79069 6.30722 2.78591 7.01107Z' fill='#5C6BFA' />
                    <path d='M2.78591 7.01107L2.77365 11.6091L8.57058 10.4215L12.0871 2.92066L3.8002 5.44392C3.18001 5.70434 2.79069 6.30722 2.78591 7.01107Z' fill='#5C6BFA' />
                  </svg>
                </div>
                <div className='flex-column gap-1 truncate'>
                  <h3 className='font-semibold'>{notification.title}</h3>
                  <p className='truncate'>{notification.message}</p>
                </div>
                <Mail size={70} color='#5C6BFA' />
              </div>
            )
          }
        </TabsContent>
      </Tabs>
    </div>
  )
}

export default NotificationsPopup

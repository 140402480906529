import { lazy } from 'react'

const lazyWithRetry = (importFunc, name) => {
  return lazy(() =>
    new Promise((resolve, reject) => {
      const hasRefreshed = globalThis.sessionStorage.getItem(`lazyWithRetry-${name}`) || 'false'

      const attempt = (retries = 3) => {
        importFunc()
          .then(resolve)
          .catch((error) => {
            if (retries === 0) {
              if (hasRefreshed === 'false') {
                // Позначаємо, що ми пробували оновити
                globalThis.sessionStorage.setItem(`lazyWithRetry-${name}`, 'true')
                globalThis.location.reload()
              } else {
                // Викидаємо помилку, якщо вже перезавантажувались
                reject(new Error('ChunkLoadError'))
              }
            } else {
              setTimeout(() => attempt(retries - 1), 1000) // Повторна спроба через 1 секунду
            }
          })
      }
      attempt()
    })
  )
}

export default lazyWithRetry

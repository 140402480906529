import { Link, useLocation } from 'react-router-dom'
import { NON_AUTHORIZED_PAGES, PAGES_SLUGS } from '@/constants/pages'
import Navigation from './components/Navigation'
import Logo from '@/assets/icons/logo.svg'

const Header = ({ mobileMenu, toggleMenu, isLogin }: { mobileMenu: boolean, toggleMenu: () => void, isLogin: boolean }) => {
  const location = useLocation()

  const isLogoDisabled = NON_AUTHORIZED_PAGES.includes(location.pathname)
      ? location.pathname
      : PAGES_SLUGS.jobs

  return (
    <header className='h-[50px] xl:h-[65px] px-[16px] md:px-[40px] flex-center-y bg-dark-default text-white shadow-lg z-50'>
      <Link
        id='logo'
        title={'Logo'}
        to={isLogoDisabled}
        className='text-[72px]'
      >
        <Logo />
      </Link>
      <Navigation
        mobileMenu={mobileMenu}
        toggleMenu={toggleMenu}
        isLogin={isLogin}
      />
    </header>
  )
}

export default Header

import * as React from "react"
import { ChevronLeft, ChevronRight } from "lucide-react"
import { DayPicker, DropdownProps } from "react-day-picker"
import { cn } from "@/utils/utils"
import { buttonVariants } from "src/shared/ui/button"
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from '@/shared/ui/select'
import { ScrollArea } from '@/shared/ui/scroll-area'

export type CalendarProps = React.ComponentProps<typeof DayPicker> & {
  monthYearOnly?: boolean  // Пропс для вибору тільки місяця і року
  rangeStart?: Date
  rangeEnd?: Date
  hideNavigation?: boolean
}

function Calendar({
                    className,
                    classNames,
                    showOutsideDays = true,
                    monthYearOnly = false,
                    rangeStart,
                    rangeEnd,
                    hideNavigation = false,
                    ...props
                  }: CalendarProps) {
  return (
    <DayPicker
      showOutsideDays={showOutsideDays && !monthYearOnly}
      className={cn("p-3", className)}
      classNames={{
        months: monthYearOnly ? "" : "flex flex-col sm:flex-row space-y-4 sm:space-x-4 sm:space-y-0",  // Приховуємо дні
        month: "space-y-4",
        caption: "flex justify-center pt-1 relative items-center",
        caption_label: "text-sm font-medium hidden",
        caption_dropdowns: "flex justify-center gap-1",
        nav: hideNavigation
        ? "hidden"
        : monthYearOnly
        ? "flex"
        : "w-full space-x-1 flex items-center flex-space-between",
        nav_button: cn(
          buttonVariants({ variant: "outline" }),
          "h-7 w-7 bg-transparent p-0 mt-0 pt-0"
        ),
        nav_button_previous: " left-1 top-1 hover:bg-primary",
        nav_button_next: " right-1 top-1 hover:bg-primary",
        table: monthYearOnly ? "flex" : "w-full border-collapse space-y-1",
        head_row: monthYearOnly ? "invisible" : "flex",
        head_cell:
          "text-muted-foreground rounded-md w-9 font-normal text-[0.8rem]",
        row: monthYearOnly ? "hidden" : "flex w-full mt-2",
        cell: "h-9 w-9 text-center text-sm p-0 relative [&:has([aria-selected].day-range-end)]:rounded-r-md [&:has([aria-selected].day-outside)]:bg-accent/50 [&:has([aria-selected])]:bg-accent first:[&:has([aria-selected])]:rounded-l-md last:[&:has([aria-selected])]:rounded-r-md focus-within:relative focus-within:z-20",
        day: cn(
          buttonVariants({ variant: "ghost" }),
          "h-9 w-9 p-0 font-normal aria-selected:opacity-100"
        ),
        // day_range_end: "day-range-end",
        day_selected:
          "bg-primary text-primary-foreground hover:bg-primary hover:text-primary-foreground focus:bg-primary focus:text-primary-foreground",
        day_today: "bg-accent text-accent-foreground",
        day_outside:
          "day-outside text-muted-foreground opacity-50 aria-selected:bg-accent/50 aria-selected:text-muted-foreground aria-selected:opacity-30",
        day_disabled: "text-muted-foreground opacity-50",
        day_range_middle:
          "aria-selected:bg-accent aria-selected:text-accent-foreground",
        day_hidden: "invisible",
        ...classNames,
      }}
      components={{
        Dropdown: ({ value, onChange, children, ...props }: DropdownProps) => {
          const options = React.Children.toArray(children) as React.ReactElement<React.HTMLProps<HTMLOptionElement>>[]
          const selected = options.find((child) => child.props.value === value)
          const handleChange = (value: string) => {
            const changeEvent = {
              target: { value },
            } as React.ChangeEvent<HTMLSelectElement>
            onChange?.(changeEvent)
          }
          return (
            <Select
              value={value?.toString()}
              onValueChange={(value) => {
                handleChange(value)
              }}
            >
              <SelectTrigger className="pr-1.5 focus:ring-0">
                <SelectValue>{selected?.props?.children}</SelectValue>
              </SelectTrigger>
              <SelectContent position="popper">
                <ScrollArea className="h-80">
                  {options.map((option, id: number) => (
                    <SelectItem key={`${option.props.value}-${id}`} value={option.props.value?.toString() ?? ""}>
                      {option.props.children}
                    </SelectItem>
                  ))}
                </ScrollArea>
              </SelectContent>
            </Select>
          )
        },
        IconLeft: ({ ...props }) => <ChevronLeft className="h-4 w-4" />,
        IconRight: ({ ...props }) => <ChevronRight className="h-4 w-4" />,
      }}
      disabled={(date) =>
        (rangeStart && rangeEnd) ? (date < rangeStart || date > rangeEnd) : false
      }
      {...props}
    />
  )
}
Calendar.displayName = "Calendar"

export { Calendar }
